let headerSaves = {
    course:{
        template:`<header-save priex-route="teachPlan" :button-lists="[
                            {name:'CourseBase',params:{course_id:$route.params.course_id},route:$route.params.course_id > 0 ? 'CourseEdit' : 'CourseCreate',title:'基本信息'},
                            {name:'CourseCont',route:'CourseCont',title:'教案内容',params:{course_id:$route.params.course_id},disabled:!($route.params.course_id > 0)},
                            {name:'CourseTask',route:'CourseTask',title:'作业列表',params:{course_id:$route.params.course_id,list_id:$route.params.list_id},disabled:!($route.params.course_id > 0)},
                      ]"></header-save>`,
        components:{
            headerSave:()=>{return import('@/views/api/teaching/course/headerSave')},
        },
    }
};
export const routes = [
    { name: 'teachPlan',meta:{title:'教案'},path: '/teachPlan',component: () => import('@/views/api/teach_plan/course/list.vue'), },
    { name: 'teachPlanMy',meta:{title:'我的教案',login:true},path: '/teachPlan/my',component: () => import('@/views/api/teach_plan/course/myList.vue'), },
    { name: 'teachPlanCourseDetail',meta:{title:'教案详情'},path: '/teachPlan/course/:course_id(\\d+)/detail'
        ,component: () => import('@/views/api/course/detail.vue')
        , props: {priexPower:'teachPlanCourse'}
        , children: [
            // { name: 'teachPlanCourseDetailMenu',meta:{title:'教案目录'},path: 'study/:list_id(\\d+)?',component: () => import('@/views/api/teach_plan/course/c/menu.vue')},
        ]
    },
    { name: 'teachPlanCourseDetailOrderSure',meta:{title:'教案购买',login:true},path: '/teachPlan/course/:course_id(\\d+)/detail/order/:order_id(\\d+)',props:true,component: {
            template:'<div><breadcrumb></breadcrumb><order-sure :order_id="order_id"></order-sure></div>',
            props:['order_id'],
            components:{
                orderSure :() => import('@/views/api/com/orderSure.vue')
            }
        }
    },
    { name: 'teachPlanCourseCreate',meta:{title:'教案模板',login:true},path: '/teachPlan/course/',redirect: '/teachPlan/course/create'
        , component: headerSaves.course
        , props: {priexPower:'teachPlanCourse'}
        , children: [
            { name: 'teachPlanCourseBase',meta:{title:'创建教案模板'},path: 'create',props:true,component: () => import('@/views/api/teach_plan/course/courseSave.vue') },
        ],
    },
    { name: 'teachPlanCourseEdit',meta:{title:'教案模板',login:true , props: {priexRoute:'teachPlanCourse',blongTitle:'教案'}},path: '/teachPlan/course/:course_id(\\d+)',redirect: '/teachPlan/course/:course_id(\\d+)/edit'
        , component: headerSaves.course
        , children: [
            { name: 'teachPlanCourseBaseEdit',meta:{title:'编辑教案模板'},path: 'edit',component: () => import('@/views/api/teach_plan/course/courseSave.vue') },
            { name: 'teachPlanCourseCont',meta:{title:'教案内容'},path: 'cont',component: () => import('@/views/api/teach_plan/course/courseCont.vue') },
            { name: 'teachPlanCourseListCreate',meta:{title:'添加条目'},path: 'list',component: () => import('@/views/api/teaching/course/listSave.vue') },
            { name: 'teachPlanCourseListEdit',meta:{title:'编辑条目'},path: 'list/:list_id(\\d+)',component: () => import('@/views/api/teaching/course/listSave.vue') },
            { name: 'teachPlanCourseTask',meta:{title:'作业列表'},path: 'task', component: () => import('@/views/api/teaching/course/taskList.vue')  },
            { name: 'teachPlanCourseTaskCreate',meta:{title:'添加作业'},path: 'task/save', component: () => import('@/views/api/teaching/questOther/taskSave.vue')  },
            { name: 'teachPlanCourseTaskEdit',meta:{title:'编辑作业'},path: 'task/:other_id(\\d+)/save', component: () => import('@/views/api/teaching/questOther/taskSave.vue')  },
            { name: 'teachPlanCourseTaskCensus',meta:{title:'作业统计详情'},path: 'task/:other_id(\\d+)/census',component: () => import('@/views/api/teaching/questOther/census.vue') },
            { name: 'teachPlanCourseTaskQuestion',meta:{title:'教案作业题目管理'},path: 'task/:other_id(\\d+)/question', component: () => import('@/views/api/teaching/questOther/chooseList.vue')  },
            { name: 'teachPlanCourseTaskInfo',meta:{title:'做作业'},path: 'task/:other_id(\\d+)/info',component: () => import('@/views/api/teaching/questOther/detail.vue')  },
        ],
    },
]